import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { graphql, Link } from 'gatsby';
export const pageQuery = graphql`
  query {
    allMdx(
      filter: {frontmatter: {type: {eq: "subcategory"}}, fileAbsolutePath: {regex: "/competitive-exams/"}}
    )
    
    {
      edges {
        node {
          frontmatter {
            type
            title
          }
          slug
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Competitive Exams`}</h1>
    <h2>{`Recruitment criteria`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/recruitment-to-the-post-of-municipal-officer-infrastructure-plgb-131g2019/"
      }}>{`Recruitment to the post of Municipal Officer (Infrastructure), PLGB-131G2019`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/recruitment-to-the-post-of-assistant-engineer-sub-divisional-officer-civil/"
      }}>{`Recruitment to the post of Assistant Engineer/ Sub Divisional Officer (Civil) 12e2019`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/ppsc-final-merit-list-criteria/"
      }}>{`PPSC Final Merit List Criteria`}</a></p>
    <h2>{`Grade 14 Engineering papers`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/building-inspector-dae-civil-ppsc-bps-14/"
      }}>{`BUILDING INSPECTOR DAE CIVIL PPSC BPS-14`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/zilledar-post-irrigation/"
      }}>{`ZILLEDAR POST IRRIGATION`}</a></p>
    <h2>{`Grade 17 Civil Engineering Original Past Papers`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/jr-engineer-civil-wapda-gm-cm-water-conducted-by-pts/"
      }}>{`Jr. Engineer (Civil) WAPDA GM (C&M ) Water Conducted by PTS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/assistant-executive-engineer-civil-fpsc-railway-past-paper/"
      }}>{`Assistant Executive Engineer (Civil) FPSC Railway Past Paper`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/municipal-officer-infrastructure-services-ppsc/"
      }}>{`Municipal Officer (Infrastructure & Services) PPSC`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/assistant-director-engineering-civil-gujranwala-development-authority-ppsc/"
      }}>{`Assistant Director Engineering (Civil) Gujranwala Development Authority PPSC`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/assistant-executive-engineer-pwd-fpsc-past-paper-2019/"
      }}>{`Assistant Executive Engineer PWD FPSC Past paper 2019`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/assistant-executive-engineer-civil-fpsc-pwd-past-paper-2020/"
      }}>{`Assistant executive engineer (Civil) FPSC PWD Past paper 2020`}</a></p>
    <div>
      {props.data.allMdx.edges.map(x => <Link to={'/' + x.node.slug} key={x.node.frontmatter.title} mdxType="Link">
            <div>
              {x.node.frontmatter.title}
            </div>
          </Link>)}
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      